import axios, { type AxiosError, type AxiosInstance, type AxiosResponse, type RawAxiosRequestHeaders } from "axios"

import Urls from "@/api/urls"
import { handle401Error as prevent401Error } from "@/utils/handle-401-error"

import { isNullOrUndefined } from "./utils/gates"
import {
    getCurrentAccessToken,
    getCurrentRefreshToken,
    isAccessTokenValid,
    isRefreshTokenValid,
    setCurrentAccessToken
} from "./utils/tokens"

const headers: RawAxiosRequestHeaders = {
    "X-Requested-With": "XMLHttpRequest"
}
const xsrfCookieName: string = "csrftoken"
const xsrfHeaderName: string = "X-CSRFToken"

const http: AxiosInstance = axios.create({
    xsrfCookieName,
    xsrfHeaderName,
    withXSRFToken: true,
    headers
})

let isRefreshing: boolean = false
let accessTokenPromise: Promise<string> | null = null

async function refreshAccessToken(): Promise<string | null> {
    if (isRefreshing) return accessTokenPromise

    isRefreshing = true

    try {
        const { data }: AxiosResponse = await axios.post(
            Urls.postTokenRefresh(),
            { refresh: getCurrentRefreshToken() },
            { headers, xsrfHeaderName, xsrfCookieName }
        )

        const newToken: string = data.access
        setCurrentAccessToken(newToken)
        return newToken
    } catch (error) {
        console.log(error)
        return null
    } finally {
        isRefreshing = false
    }
}

http.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status >= 200 && response.status < 303 && response.headers?.location) {
            // eslint-disable-next-line no-restricted-globals
            location.href = response.headers.location
        }

        return response
    },
    (error: AxiosError) => {
        return Promise.reject(error)
    }
)

http.interceptors.request.use(
    async request => {
        if (request.data && request.data.type && request.data.type === "html") {
            let form = ""

            request.data.body?.forEach(
                (item): void => ((form += `${item.name}=${encodeURIComponent(item.value)}&`), void 0)
            )

            request.data = form
        }

        const token: string = getCurrentAccessToken()

        if (token && !isAccessTokenValid()) {
            accessTokenPromise = refreshAccessToken()
            request.headers.Authorization = `Bearer ${await accessTokenPromise}`
        } else if (token) {
            request.headers.Authorization = `Bearer ${token}`
        }

        if (!isNullOrUndefined(getCurrentRefreshToken()) && !isRefreshTokenValid()) {
            prevent401Error()
        }

        return request
    },
    (error: AxiosError) => Promise.reject(error)
)

export { http }
