import { AuthUrls } from "@/constants"
import { ESessionStorageServiceItemKey, EStorageServiceType, StorageService } from "@/services"

const localStorageService: StorageService = new StorageService(EStorageServiceType.Local)
const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

function handler(): void {
    try {
        localStorageService.clearStorage()
        sessionStorageService.clearStorage()
        sessionStorageService.setItem(ESessionStorageServiceItemKey.UserLogout, true)
        window.location.replace(AuthUrls.LOGIN)
    } finally {
        void 0
    }
}

function handle401Error(): void {
    setTimeout(handler, 0)
}

export { handle401Error }
