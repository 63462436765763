import { storageUtils } from "./utils"

enum ELocalStorageServiceItemKey {
    TokenAccess = "tokenAccess",
    TokenRefresh = "tokenRefresh",
    SocialComplete = "socialComplete",
    SimulabBannerVisible = "simulabBanner",
    ArchiveModalVisible = "show-archive-modal",
    SubUserList = "subUserList",
    MainUserLoggedOut = "mainUserLoggedOut"
}

class LocalStorageService extends Storage {
    static setItem<T>(key: ELocalStorageServiceItemKey, value: T): void {
        localStorage.setItem(key, storageUtils.setStorageItemValue<T>(value))
    }

    static getItem<T>(key: ELocalStorageServiceItemKey): T | null {
        return storageUtils.getStorageItemValue<T>(localStorage.getItem(key))
    }

    static removeItem(key: ELocalStorageServiceItemKey): void {
        localStorage.removeItem(key)
    }

    static clear(): void {
        localStorage.clear()
    }
}

export { LocalStorageService, ELocalStorageServiceItemKey }
