import { isNullOrUndefined, isString } from "$/utils/gates"

function getStorageItemValue<T>(value: string | null): T | null {
    if (isNullOrUndefined(value)) return null

    try {
        return JSON.parse(value) as T
    } catch {
        return value as T
    }
}

function setStorageItemValue<T>(value: T): string {
    return isString(value) ? value : JSON.stringify(value)
}

const storageUtils = { getStorageItemValue, setStorageItemValue }

export { storageUtils }
