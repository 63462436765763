import { Settings } from "luxon"

import { FALLBACK_LANG } from "$/constants"

import type { TUserModel } from "@/adapters"
import { Language } from "@/models/state"

import { isNullOrUndefined } from "./gates"

export type TranslationObj = {
    [key in Language]?: string
}

export const getUserLanguage = (user?: TUserModel) => {
    const defaultLanguage = user?.lang ?? FALLBACK_LANG

    if ("navigator" in window && window !== undefined) {
        const langKey = navigator.language.split("-")[0]
        const languages = !isNullOrUndefined(JSAPP_CONF?.available_languages)
            ? Object.keys(JSAPP_CONF?.available_languages)
            : []
        const language = languages.find(lang => lang === langKey)
        return defaultLanguage !== FALLBACK_LANG && user?.lang?.length > 0 ? defaultLanguage : language
    }

    return defaultLanguage
}

/**
 * Returns needed translation by key from translation object
 * @deprecated Deprecated since language is taken not from JSAPP_CONF, but from user instead (WISER-3783)
 * @param obj An object instanceof TranslationObj
 * @param lang instanceof Language, preferable language to be used
 * @return string taken from TranslationObj or empty string if no passed or default key is met
 */
export const withTranslation = <T extends TranslationObj>(obj: T, lang: Language = FALLBACK_LANG): string => {
    return obj?.[lang] ?? obj?.en ?? ""
}

export const setLuxonLocale = (userLang: Language) => {
    Settings.defaultLocale = userLang ?? "en-US"
}
